@media screen and (max-width: 1000px) {
    .main-titles {
        z-index: 999;
        // margin-left: auto;
        // margin-right: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 35px;
        padding:0 !important;
        margin-bottom: 60px !important;
      
        &>* {
            margin: 0;
            padding: 0;
            text-align: center;
        }

        &__title {
            font-size: 80px!important;
        }
        &__subtitle{
            font-size: 35px!important;
        }
    }

    .padding100gap100 {
        width: 100% !important;
        position: relative;
        //padding-top: 100px;
        align-items: center;
        justify-content: center;
        gap: 25px;
        flex-direction: column;
        height: auto;

        .line {
            display: none;
        }

        .alanImg {
            filter: brightness(0.65);
            width: 90vw;
        }
    }
}
@media screen and (max-width: 767px) {
    .main-titles {
        gap: 20px;
        &__title {
            font-size: 65px!important;
            line-height: 67.5px!important;
        }

        &__subtitle {
            font-size: 25px!important;
        }
    }
}

@media screen and (max-width: 420px) {
    .main-titles {
        gap: 20px;
        min-width:0;
        &__title {
            font-size: 50px!important;
            line-height: 22.5px;
            //line-height: 60px!important;
            //white-space:normal !important;
        }

        &__subtitle {
            font-size: 20px!important;
            width:100%;
        }
    }
}


@media screen and (min-width: 2000px) {
    section {
        overflow: visible !important;
    }


    .App {
        overflow: visible !important;

        .reviews-page{ 
            width: 110% !important;
            overflow: visible !important;
            &__form {      
                &__cloud {
                    background-position: center center;
                    background-size: 100% !important;
                    
                }
            }
        }

        // .help-page {
        //     &__content {
        //         &__left,
        //         &__right {
        //             width: 100%;
        //             background-size: 100% !important;
        //         }
        //     }
        // }
    }
}


@media screen and (max-width: 475px) {
    .main-titles__title {
        white-space: inherit !important;
    }
}


