@import "../styles/variables.scss";

.contact-me {
    width: calc(100% - 20px);
    max-width: 500px;
    height: fit-content;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: white;
    z-index: 3000;
    padding: 35px;
    flex-direction: column;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    border-radius: 20px;
    opacity: 1;
    will-change: opacity;
    will-change: bottom;
    box-shadow: 5px 5px 40px rgba(150, 150, 150, 0.5);
    background-color: white;
    gap: 20px;

    img.logo {
        position: relative;
        margin-bottom: 10px;
        width: 100px;
    }

    a {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        gap: 10px;
        text-decoration:none !important;
        padding: 7.5px;
        border-radius: 5px;
        transition: all .25s ease; 
        font-size: 25px;

        .contact-img {
            width: 40px !important;
        }

        p {
            text-decoration: none;
            font-weight: bold;
            font-size: 22.5px;
            color: $mid-color;
        }

       
    }

    a:hover {
        color: white;
        background-color: $mid-color;
        p {
            color: white;
        }
        img {
            filter: invert(.5) brightness(3);
        }
        
    }
   
}

.contact-me.hidden {
    display: none;
    opacity:0;
    transition: all .25s ease;
}

@media screen and (max-width: 480px) {
    .contact-me {
        align-items: center;
        justify-content: space-around;
        text-align: center;
        padding: 20px;
        gap: 10px;

      
        a {
            gap: 0px;
            flex-direction: column; 
            p {
                font-size: 20px;
                margin: -4px;
            }
        }
    }
}

@media screen and (max-width: 350px) {
    .contact-me {
        padding: 20px;
        gap: 10px;

        .logo {
            display:none;
            
        }
    }
}