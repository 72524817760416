$wave-vector-color-1: #CE42FF;
$wave-vector-color-2: #710084;
$lightest-color: #B74BFF;
$mid-color: #C464FF;
$darkest-color: #67217F;
$reviews-form-color: #9100C3;
$box-shadow-light: 0px 0px 24px 0px rgba(66, 68, 90, 0.1);
$glowup-main-color: #8911B8; 
$silver: #C4C4C4;
$gold: #EECA56;
$diamond: #70DDFF;





$colors: (
    "wave-vector-color-1": $wave-vector-color-1,
    "wave-vector-color-2": $wave-vector-color-2,
    "lightest-color": $lightest-color,
    "mid-color": $mid-color,
    "darkest-color": $darkest-color,
    "reviews-form-color": $reviews-form-color,
    "box-shadow-light": $box-shadow-light,
    "silver": $silver,
    "gold": $gold,
    "diamond": $diamond,
);



