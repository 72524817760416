@import "../styles/variables.scss";


.product-options {

    display:flex;
    flex-direction: column;
    gap: 15px;
    padding-left: 7.5px;
    


    &__title {
        font-size: 25px;
        line-height: 22.5px;
        //margin: 6.5px;
        font-weight: 700;
        margin-bottom: 7.5px;
    }

    &__values {
        display: flex;
        flex-direction: row;
        gap: 7.5px;
       
    }

    &__value {
        font-size: 20px;
        line-height: 22.5px;
        font-weight: 100;
        padding: 6px 12.5px;
        border: 1px solid;
        border-radius: 5.5px;

        transition: all .25s ease;

        &.selected {
            background-color: $mid-color;
            color: white;
        }
        hh5 {
            font-size: 20px;
            line-height: 22.5px;
            font-weight: 100 !important;
            padding: 6px 12.5px;
            border: 1px solid;
            border-radius: 5.5px;
        
        }

        &:hover {
            cursor: pointer;
            color: white;
            background-color: $mid-color;
        }
    }

    &__value {
        font-size: 20px;
    }
}


@media screen and (min-width: 1px) and (max-width: 900px) {


    .product-options {
        align-items: center;
        gap: 20px;

        &__title {
            font-size: 30px;
            margin-bottom: 12.5px;
        }
    }
}