.offer-page__offerts__content__block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    border-radius: 30px;
    box-shadow: 2px 4px 15px rgba(0, 0, 0, 0.15);
    padding: 20px;
    margin: 20px;
    min-width: 300px;
   

    &__title {
        font-size: 50px;
        color: white;
        margin-bottom: 5px;
    }

    &__subtitle {
        font-size: 21px;
        color: white;
        font-weight: 400;
        margin-bottom: 10px;
        max-width: 325px;
        margin-left: 3.75px;
    }

    &__price {
        font-size: 60px;
        color: white;
        font-weight: 500;
        margin-bottom: 2.5px;
        margin-left: 3.75px;
        font-weight: bolder;

        span {
            position: relative;
        }

        .currency {
            font-size: 25px;
            right: 2px;
        }

        .line {
            position: relative;
            bottom: 3px;
            margin: 1.25px;
            font-weight: 650;
        }
        .cycle {
            font-size: 17.5px;
            position: relative;
            bottom: 14.25px;
            left: 2px;
        }
    }

    &__start-now {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(100% + 5px);
        padding: 17.5px;
        gap: 4px;
        background: #FFFFFF;
        border-radius: 12.5px;
        border: none;
        color: #A2A2A2;
        font-size: 19px;
        font-weight: 550;
        transition: all .25s ease;
        flex-shrink:0;
        position:relative;
        right: 2.5px;
        box-shadow: 3px 3px 15px  rgba(0, 0, 0, 0.175); 
      

        &:hover {
            cursor: pointer;
            scale: 1.05;
        }

        &--silver {
            color: #aeaeae;
        }

        &--gold {
            color: #f6ce4b;

        }

        &--diamond {
            color: #48c9f0;
        }
    }

    &__start-now--diamond {
        color: #48c9f0;
    }

    &__included {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12.5px;
        width: 100%;

        &__el {
            width: 100%;
            max-width:400px;
         
            background: #FFFFFF;
            border-radius: 12.5px;
            display: flex;
            font-size: 10px;
            align-items: center;
            font-weight: 300;
            padding: 10px 10px;
            box-shadow: 3px 3px 12.5px rgba(0, 0, 0, 0.1);
        }

        h1 {
            font-size: 16.15px;
            font-weight: 400;
        }

        img {
            height: 25px;
            margin-right: 10px;
          
        }

        &__el--true {
            color: #2fc827;
        }

        &__el--false {
            color: #ff0b0b8d;
        }

        &__btn {
            width: 100%;
           
            align-self: end;
            border-radius: 12.5px;
            border: none;
            background-color: white;
            margin-top: 10px;

            &:hover {
                cursor: pointer;
                transform: scale(1.1);
            }
        }
    }
}

.offer-block-flipped {
    position:relative;
    border-radius: 20px;
    border-style: solid;
    border-width: 1.75px;
    padding: 20px;
    display:flex;
    flex-direction:row;
    align-items: flex-start;
    margin: 20px;
    width:100%;
    max-width: 600px;
    min-width:200px;
    transition: all .25s ease;
    

    h1 {
        font-size: 50px;
    }
    h2 {
        margin-bottom: 2.5px;
    }
    h3 {
        font-size: 20px;
    }
    .texts {
        display:flex;
        flex-direction:column;
        justify-content:space-between;
        margin-left: 20px;
        margin-right: 20px;
        width: 100%;
        gap: 20px;
    }
}


@media screen and (max-width: 768px) {
    .offer-block-flipped {
        padding: 20px;
        max-width: 95vw;
    }


     .offer-page__offerts__content__block {
         max-width: 95vw;
     }
}

@media screen and (max-width: 400px) {
    .offer-page__offerts__content__block {
        margin: 10px;

    }
}
  
