@import "../styles/variables.scss";

.header-wrapper {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;

    header {
        height: 55px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 25px;
        padding-right: 50px;
        background-color: #ffffff;

        &>* {
            position: relative;
            top: 7.5px;
        }
        img {
            width: 60px;
            height: 60px;
            top: 3px;
            left: -7.5px;
            
        }

        nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 75px;
            margin-bottom: 3px;
         
            .active, span {
                padding: 7.5px 15px !important;
                background-color: $lightest-color;
                border-radius: 5px;
                color: white;
                font-size: 18px;
            }
            span {
                padding: 10px 17.5px !important;
            }

            a {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 420;
                font-size: 17.2px;
                line-height: 24px;
                text-decoration: none;
                text-transform: uppercase;
                color: $lightest-color;
                transition: all .25s ease;

                
                &:hover {
                    padding: 7.5px 15px;
                    background-color: $lightest-color;
                    border-radius: 5px;
                    color: white;
                    font-size: 18px;
                }

                span {
                    font-weight: 500;
                }
            }
        }
    }

    .wave-border {
        height: 62.5px;
    }
}

.ham-btn {
    position:fixed;
    top: 15px;
    right: 17.5px;
    display:flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    flex-direction: column;
    padding: 5px;
    z-index: 3000;
    transition: all .25s ease;

    &:hover {
        cursor:pointer;
        scale: 1.05;
    }


    &__line {
        width: 35px;
        height:3.5px;
        border-radius: 2px;
        background-color: $lightest-color;
        position:relative;
        transition: all .25s ease;

        &.clicked:nth-child(1) {
            transform: rotate(45deg) translate(5px, 8.25px);
        }
        &.clicked:nth-child(2) {
            opacity:0;
        }
        &.clicked:nth-child(3) {
            transform: rotate(-45deg) translate(5px, -8.25px);
        }
    }
}


.ham-overlay {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100vh;
    z-index:1000;
    background-color: rgba(0,0,0,0.5);
}


@media only screen and (max-width: 768px) {
    .header-wrapper {

        .ham-btn {
            top: 20px !important;

        }
      
    }
}