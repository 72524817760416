* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.App {
    background-color: black;
}

section {
    scroll-margin: 110px;
   //max-width:2000px;
    margin-left: auto;
    margin-right: auto;
}



.about-me, .reviews-page {
    max-width: 1400px;
}

html,body {
    scroll-behavior: smooth !important;
    overflow-x: hidden;
}

@import "variables";

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.main-titles {
    &.centered {
        &>* {
            text-align: center;
        }
    }
    z-index: 100;
    min-width: 400px;
    max-width: 600px;
    color: white;
    position:relative;
    overflow: visible;
    margin-bottom: 40px;


    &__title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 900;
        font-size: 90px;
        line-height: 75px;
        text-align: left;
        margin-bottom: 20px;
        white-space: nowrap;
       
    }

    &__subtitle {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 250;
        font-size: 40px;
        //margin-bottom: 50px;
        //line-height: 30px;
        margin-left: 10px;
        margin-right: 10px;
        white-space: wrap !important;
    }
}

.padding100gap100 {
    padding: 50px 50px;
    gap: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;

    & > * {
        width: 50% !important;
    }
}

.alanImg,
.dottedArrow {
    width: 30vw;
    min-width: 450px;
    max-width: 700px;
    position:relative;

    &--centered {
        position: relative;
        left: 33%;
    }

}

.dottedArrow {
    width: 25vw;
}

.alanImg {
    position: relative;
    box-shadow: inset 50px -50px 150px 120px #000000;
}


@media screen and (min-width: 1000px) and  (max-width: 1300px) {
    .padding100gap100 {
        padding: 50px 150px;
    }
}


@media screen and (min-width: 1px ) and (max-width: 450px) {
    .main-titles {
        width: 95vw;
        //white-space: nowrap;
    }
}











  