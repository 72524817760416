@import "../styles/variables.scss";

.close-btn {
    outline: none;
    border: none;
    width: 30px;
    height: 30px;
    padding: 5px;
    background-color: transparent;
    position: absolute;
    right: 0;
    top: 0;
    justify-content: center;
    transition: all .25s ease;
    margin: 12.5px;

    &:hover {
        cursor: pointer;
        scale: 1.1;
    }

    div {
        width: 25px;
        height: 3.25px;
        border-radius: 1px;
        background-color: $lightest-color;
    }

    .first {
        transform: rotate(45deg);
        top: 3px;
        position: relative;
    }

    .second {
        transform: rotate(-45deg);
    }
}