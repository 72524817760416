@import "../styles/variables.scss";

.product-block {
    border: 2.75px solid rgba($lightest-color, .6);
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    //width: fit-content;
    flex-direction: row;
    padding: 30px;
    //position: relative;
    transition: all .25s ease;
    max-width: 1200px;
    color: $mid-color;
    margin: 40px;

    &__img {
        display:flex;
        flex-direction: column;
        align-items: center;
        width: fit-content;
        width: 50%;
        img.current {
            width: 100%;
            //width: 395px;
            //padding: 20px;
            border-radius: 20px;
            border: 1.75px solid rgba(183, 75, 255, 0.6);
            //min-width: 250px;
           //max-width: 400px;
            //aspect-ratio: 2/2.25;
        }

        .product-block__slider {

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 10px;
            will-change: cursor, scale;
            transform: all .25s ease;
            margin:0;

            img {
                width: 57.5px !important;
                height: 65px;
                border: 1.75px solid rgba(183, 75, 255, 0.6);
                padding: 10px;
                border-radius: 5px;
               

                &:hover {
                    cursor: pointer;
                    scale: 1.05;

                }

            }
        }


    }

    &>* {
        margin: 15px;

        &>* {
            margin: 20px;
        }
    }

    .product-photo {
        width: 50%;
        max-width: 400px;
        border-radius: 20px;
        min-width: 300px;
        border: 1.75px solid rgba($lightest-color, .6);
        cursor: pointer;
    }

    &__title {
        text-align: left;
        font-size: 50px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        width: auto;

        h1 {
            font-size: 60px;
            line-height: 60px;
            text-align: left;
        }


        h2 {
            text-align: left;
            font-size: 27.5px;
            line-height: 30px !important; 
        }

        h3 {
            font-size: 20px;
        }

        ul {
            max-width: 600px;

            li {
                font-size: 20px !important;
                line-height: 22.5px;
                margin: 6.5px;
            }
        }

    }


}

@media screen and (min-width: 1px) and (max-width: 900px) {

    .product-block {
        flex-direction: column;
        padding: 25px;
        margin: 20px;

        &__title {
            align-items: center;
            text-align: center;

            h1 {
                text-align: center;
            }

            h2 {
                text-align: center;
                line-height: 25.5px;
            }
        }


        .pricing__normal-price__line {
            left: 25% !important;
        }


    }
}

.promo-block {
    max-width: 350px;
}

// @media screen and (min-width: 1px) and (max-width: 385px) {
//     .product-block {
//         scale: 0.85;
//     }
// }

// @media screen and (min-width: 1px) and (max-width: 385px) {
//     .product-block {
//         scale: 0.8;
//     }
// }

@media screen and (min-width: 1px) and (max-width: 500px) {
    .product-block {
     

        & > *{
            margin: 10px;
        }


        &__title {

            & > * {
                margin: 15px;
            }
            h1 {
                text-align: center;
                font-size: 50px;
                line-height: 45px;
                width: 350px;
             
            }
            h2 {
                font-size: 25px;
            }
        }  
    }
}