.help-page{
    width: 100%;
    height: 100%;
    background-color: black;
    font-family: 'Inter';
    display: flex;
    flex-direction: column;
    &__header{
        color: white;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 60px;
        &__content{
            margin-bottom: 20px;
            flex-direction: column;
            margin-left: 35px;
            margin-right: 35px;
            .title{
                line-height: 90px;
                text-align: center;
            }
            .subtitle{
                text-align: center;
            }
        }
        .arrow{
            width: 12vw;
            position: relative;
            top: 80px;
            min-width: 200px;
            &--left{
                transform: rotate(15deg);
                
            }
            &--right{
                transform: rotate(-15deg);
                //top: 120px;
            }
        }
    }
    &__content{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        overflow: hidden;
        padding-bottom: 100px;
            &__left, 
            &__right{
                height: 100%;
                width: 50%;
                background-repeat: no-repeat;
                background-size: 60%;
                display: flex;
                //padding: 200px;
                justify-content: center;
                align-items: left;
                flex-direction: column;
                h1{
                    color: white;
                    font-family: 'Inter';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 25px;
                    line-height: 55px;
                    display: flex;
                    align-items: center;
                    img{
                        width: 6px;
                        margin-right: 5px;
                    }
                }
            }
            &__left{
                background-position: left;
            }
            &__right{
                background-position: right;
            }

            
    }
}

$blob1: url('../blobs/blob-helpPage-responsive-1.svg');
$blob2: url('../blobs/blob-helpPage-responsive-2.svg');

@media(max-width: 1900px){
    .help-page{
        &__content{
            &__left{
                background-image: url('../blobs/blob-helpPage-responsive-1.svg')!important;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 90%;
            }
            &__right{
                background-image: url('../blobs/blob-helpPage-responsive-2.svg')!important;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 90%;
            }
        }
    }
}

@media(max-width: 1400px){
    .help-page{
        &__header{
            display: flex;
            // flex-direction: column;
            align-items: center;
            justify-content: start;
            justify-content: center;
            flex-wrap: nowrap !important;
            
            .arrow--right{
                // display: block;
                // top: 0;
                top: 80px;
                // transform: rotate(20deg);
            }
        }
        &__content{
            height: 1800px;
            flex-direction: column;
            gap: 50px;
            &__left,
            &__right{
                width: 100%;
                background-size: 70%;
            }

            &__right {
                margin-top: -15%;
            }
            
        }
    }
}

@media(max-width: 1000px){
    .help-page{
        &__content{
            &__left,
            &__right{
                width: 100%;
                background-size: 90%;
            }
        }
    }
}

@media(max-width: 800px){
    .help-page
    {
        &__header{
            .arrow{
                width: 150px;
            }
        }
        &__content{
            height: 1600px;

           
        }
    }
}

@media(max-width: 700px){
    .help-page{
     
        &__content{
            height: 1300px;
            &__left,
            &__right{
                width: 120%;
                background-size: 80%;
            }
            &__left{
                background-image: url('../blobs/media-blob-helpPage-1.svg')!important;
                
            }
            &__right{
                background-image: url('../blobs/media-blob-helpPage-2.svg')!important;
            }
        }
    }
}

@media(max-width: 600px){
    .help-page{
        &__header{
            gap: 40px;
            &__content{
                .main-titles{
                    &__title{
                        font-size: 70px;
                    }
                    &__subtitle{
                        font-size: 35px;
                    }
                }
            }
        }
    }
}

@media(max-width: 550px){
    .help-page{
        &__header{
            gap: 30px;
            .arrow{
                width: 120px;
            }
        }
        &__content{
            height: 1100px;
        }
    }
}


@media(max-width: 450px){
    .help-page{
        &__header{
            gap: 25px;
            .arrow{
                width: 100px;
            }
            &__content{
                .main-titles{
                    &__title{
                        font-size: 65px;
                    }
                    &__subtitle{
                        font-size: 30px;
                    }
                }
            }
        }
        &__content{
            height: 900px;
        }
    }
}

@media(max-width: 400px){

    .help-page{
        &__header{
            gap: 10px;
            &__content{
                .main-titles{
                    &__title{
                        font-size: 65px;
                    }
                    &__subtitle{
                        font-size: 30px;
                    }
                }
            }
        }
        &__content{
            height: 850px;
            justify-content: center;

            &__left {
               margin-bottom: -50px;
            }
          

            
        } 
    }
}


@media screen and (max-width: 375px) {
    .help-page__content__left {
        margin-bottom: -75px;
    }
}





