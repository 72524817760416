@import "../styles/variables.scss";

.offer-page {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    margin-top: -5px;
    max-width: 4000px;
    background-color: white;
    align-items: center;

    &__content {
        width: 100%;
        background-color: white;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        flex-wrap:wrap;
        padding-top: 25px;
        padding-bottom: 40px;
        max-width: 1400px;
        margin-left: auto;
        margin-right: auto;
    }

    &__offerts {
        width: 100%;
        padding-bottom: 50px;
        background-color: white;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        &__title {
            color: $mid-color;
        }

        &__content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;

            .silver {
                background: linear-gradient(180deg, #C4C4C4 -1.08%, #F8F8F8 129.97%);
            }

            .gold {
                background: linear-gradient(179.98deg, #EECA56 0.01%, #FFE89C 101.38%);
            }

            .diamond {
                background: linear-gradient(180deg, #70DDFF 0%, rgba(191, 240, 255, 0.25) 157.16%);
            }
        }

    }
}

@media(max-width: 1700px) {
    .offer-page {
        &__offerts {
            &__content {
                gap: 50px;
            }
        }
    }
}

@media(max-width: 1400px) {
    .offer-page {
        &__offerts {
            &__content {
                gap: 50px;
                flex-wrap: wrap;
            }
        }
    }
}

@media(max-width:1200px) {
    .offer-page {
        &__content {
            flex-direction: column;
            justify-content: space-between;

            &__training {
                align-items: start;
                text-align: left;

                &__title,
                &__text {
                    text-align: left;
                    flex-direction: reverse;
                }
            }

            &__training,
            &__diet {
                padding: 100px;
                height: 750px;
                width: 550px;
            }
        }
    }
}