@import "../styles/variables.scss";


.your-steps-wrapper {
    display: flex;
    flex-direction: column;
    z-index: 100;
    position: relative;
  
   

    .wave-border--rotated {
        animation-delay: 2s;
    }

    &__inner {
        padding: 70px 30px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: nowrap;
        
        

        &__element--1::before {
            content: '1.';
        }

        &__element--2::before {
            content: '2.';
        }

        &__element--3::before {
            content: '3.';
            left: -5px !important; // szybko szpachla by Oskar dev
        }

        &__element {
            opacity:0;
            display: flex;
            flex-direction: row;
            overflow: visible;
            align-items: center;
            justify-content: center;
            padding: 65px;
            background-size: 100%;
            background-position: center;
            background-repeat: no-repeat;
            position: relative;
            height: 420px; 
            margin:-30px 20px -30px 20px;
            right: 75px;
            transform: scale(0.8);
            will-change: opacity;
            will-change: transform;
         


            &::before {
                position: absolute;
                top: 20px;
                left: 10px;
                font-size: 30px;
                color: rgba($lightest-color, .6);
                font-weight: bolder;
                font-family: 'Inter';
            }

            &.wrapped {
                &::before {
                    top: 60px;
                }
            }

            &__text {
                flex-shrink: 0;     

                h1 {
                    color: $lightest-color;
                    text-transform: uppercase;
                }

                h2 {
                    color: rgba($lightest-color, 0.5);
                    font-weight: 400;
                    font-size: 20px;

                }
            }

            &__icon {
                width: 50px;
                height: 50px;
                margin-left: 7.5px;
            }
        }

    }
}

@media screen and (max-width: 600px) {
    #root {
        .App {

            .your-steps-wrapper {
                overflow: hidden;

                &__inner {
                    padding:15px 0;
                }
            }
        }
    }
}
@media screen and (max-width: 385px) {
    #root {
        .App {
            .your-steps-wrapper {
                overflow: hidden;

                &__inner {
                    //padding: 15px;

                    &__element {
                        padding: 22.5px;
                        margin-top: -75px;
                        margin-bottom: -75px;
                        //flex-shrink:0;
                        &__text {
                            //width: 85vw;
                            width:auto;

                            h1 {
                                font-size: 25px;
                                
                            }

                            h2 {
                                font-size: 17.5px;
                                
                            }
                        }

                        &::before {
                            left: 25px !important;
                            top: 75px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 365px) and (max-width: 400px) {
    #root {
        .App {
            .your-steps-wrapper {
                overflow: hidden;

                &__inner {
                    padding: 15px;

                    &__element {
                        padding: 25px;
                        margin-top: -60px;
                        margin-bottom: -60px;


                        &__text {
                            //width: 85vw;

                            h1 {
                                font-size: 27.5px;
                                
                            }

                            h2 {
                                font-size: 19px;
                                
                            }
                        }

                        &::before {
                            left: 25px !important;
                            top: 65px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 401px) and (max-width: 475px) {


    #root {
        .App {
            .your-steps-wrapper {
                overflow: hidden;

                &__inner {
                    padding: 15px;

                    &__element {
                        padding: 45px;
                        margin-top: -40px;
                        margin-bottom: -40px;

                        &__text {
                            h1 {
                                font-size: 27.5px;
                            }

                            h2 {
                                font-size: 18.75px;
                            }
                        }

                        &::before {
                            left: 25px !important;
                            top: 45px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 475px) and (max-width: 999px) {


    #root {
        .App {
            .your-steps-wrapper {
                overflow: hidden;

                &__inner {

                    &__element {
                        &::before {
                            left: 30px !important;
                            top: 40px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 999px) and (max-width: 1574px) {


    #root {
        .App {
            .your-steps-wrapper {
                overflow: hidden;
                &__inner {
                    &__element {
                        padding: 110px;
                        
                        &__text {
                            h1 {
                                font-size: 30px;
                            }
                            h2 {
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width:1px) and (max-width: 400px) {
    #root {
        .App {
            .your-steps-wrapper {
                overflow: hidden;
                &__inner {
                    &__element {
                        padding: 110px;
                        
                        &__text {
                            h1 {
                                font-size:20px;
                            }
                            h2 {
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }
    }
}
@media screen and (min-width:401px) and (max-width: 550px) {
    #root {
        .App {
            .your-steps-wrapper {
                overflow: hidden;
                &__inner {
                    &__element {
                        padding: 110px;
                        
                        &__text {
                            h1 {
                                font-size:27.5px;
                            }
                            h2 {
                                font-size: 19px;
                            }
                        }
                    }
                }
            }
        }
    }
}



