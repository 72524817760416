@import "../styles/variables.scss";

.footer {
    position: relative;
    z-index: 10;
    width: calc(100% + 4.25px);  
    min-height: 775px;
    padding: 50px;
    background-color: rgb(0, 0, 0);
    background-position: right top, right bottom;
    background-repeat: no-repeat;
    top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    
    
    &__flex1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        z-index: 20;
        width: 75%;
        flex-wrap: wrap;
        max-width: 1200px;

        &__element {
            display: flex;
            align-items: left;
            justify-content: flex-start;
            flex-direction: column;
            margin: 50px 50px 50px 0;
            color: white;
            flex-shrink: 0;

            &>* {
                width: 100%;
            }

            a:first-child {
                min-width: 225px;
            }

            h1 {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 800;
                font-size: 40px;
                line-height: 24px;
                text-align: left;
                color: white !important;
                margin-bottom: 15px;
                letter-spacing: 1px;
            }

            p {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 300;
                font-size: 20px;
                line-height: 24px;
                text-align: left;
                color: white;
                padding: 5px 10px;
                border-radius: 5px;
                transition: all .25s ease;

                &:hover {
                    cursor: pointer;
                    background-color: white;
                    color: $darkest-color;
                }

                img {
                    width: 20px;
                    height: 20px;
                    margin-right: 5px;
                    position: relative;
                    top: 2px;
                }
            }

            p:hover img {
                filter: invert(0.75);
            }
        }
    }

    &__flex2 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        z-index: 20;
        gap: 20px;
        color: rgb(240, 240, 240);

        div {
            height: 30px;
            width: 1px;
            background-color: rgb(240, 240, 240);
        }

        p {
            padding: 5px 10px;
            border-radius: 5px;
            transition: all .25s ease;
            text-align: center;

            &:hover {
                cursor: pointer;
                background-color: white;
                color: $darkest-color;
            }
        }
    }

    &__socials {
        position: absolute;
        bottom: 125px;
        right: 40px;
        z-index: 100;
        display: flex;
        flex-direction: column;
        gap: 12.5px;

        img {
            width: 45px;
            height: 45px;
            transition: all .25s ease;
        }

        img:hover {

            scale: 1.1;
        }

        img.facebook {
            position: relative;
            top: -5px;
        }
    }

    .signature {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 9;
        padding-right: 10px;

        h1 {
            font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
            font-weight: 500;
            letter-spacing: 5px;
            font-size: 200px;
            text-align: right;
            color: rgba($lightest-color, 0.3);
        }
    }
}

.copyright {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: rgb(20, 20, 20);
    color: #E598FF;
    font-weight: 500;
    z-index: 1000;
    flex-direction: column;

    p {
        img {
            position: relative;
            top: 4px;
            right: 3px;
        }
    }

    span {
        position: absolute;
        right: 0;
        bottom: 0;
        padding-right: 15px;
        padding-bottom: 15px;

        p {
            color: rgb(100, 100, 100);

            a {
                color: rgb(100, 100, 100);
                text-decoration-thickness: 1px;

            }
        }
    }
}

@media screen and (max-width: 1150px) {
    .footer {
        &__flex1 {
            display: flex;
            width: 90%;
        }
    }
}

@media screen and (max-width: 950px) {
    .footer {
        padding: 150px 20px 30px 20px; 
        .signature{
            h1{
                font-size: 150px;
            }
        }
        &__flex1 {
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            margin: 0;
            &__element {
                margin: 40px 0;
                //margin: 30px;
                justify-content: center;
                h1 {
                    text-align: center;
                }
                p {
                    text-align: center;
                }
            }
        }
        &__flex2 {
            height: 70px;
            margin-left: auto;
            margin-right: auto;
            gap: 10px;
            width: 100vw;

            p {
                width: 20vw;
                min-width: 120px;

            }  
        }
    }
}

@media(max-width: 850px){
    .footer{
        &__flex2 {
            width: 100%;
        }
    }
}

@media(max-width: 750px){
    .footer{
        .signature{
            h1{
                font-size: 110px;
            }
        }
    }
}

@media(max-width: 600px){
    .copyright{
        align-items: start;
        justify-content: center;
        p{
            margin-left: 10px;
            font-size: 15px;
        }
    }
}

@media(max-width: 550px){
    .footer{
        .signature{
            h1{
                font-size: 90px;
                margin-bottom: 5px;
            }
        }
    }
}

@media(max-width: 450px){
    .footer{
        .signature{
            h1{
                font-size: 70px;
                margin-bottom: 10px;
            }
        }
        &__flex2{
            gap: 5px;
            p{
                font-size: 14px;
            }
        }
    }
    .copyright{
        p{
            margin-left: 10px;
            font-size: 14px;
            min-width: 80px;
        }
    }
}
