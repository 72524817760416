.overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.5);
    z-index: 10000;
    top:0;
    left:0;
    opacity:0;
    will-change: opacity, y;
}