@import "../styles/variables.scss";

.home-page {
    background-color: black;
    width: 100%;
    height: 100vh;
    color: white;
    font-family: montserrat;
    position: relative;

    &__text {
        opacity: 0;
        font-size: 60px;
        font-weight: bold;
        line-height: 90px;
        position: absolute;
        z-index: 100;
        top: 50%;
        left: -20%;
        transform: translateY(-50%);
        will-change: left;
        will-change: opacity;

        span {
            font-weight: 300;
        }

        h3 {
            font-family: montserrat;
            font-size: 47.5px;
            font-weight: 300 !important;
            line-height: 30px;
            color: rgb(190, 190, 190);
            margin-top: 20px;
        }

        .btn {
            font-family: montserrat;
            padding: 30px 75px;
            border-radius: 20px;
            color: rgb(240, 240, 240);
            background-color: $darkest-color;
            outline: none;
            border: none;
            font-size: 17.5px;
            font-weight: 400;
            margin-top: 50px;
            transition: all .25s ease;

            &:hover {
                background-color: rgba($mid-color, 0.75);
                padding: 30px 80px;
                cursor: pointer;
            }
        }
    }

    &__image {
        opacity: 0;
        width: 50%;
        background-color: white;
        position: absolute;
        top: 50%;
        right: 10%;
        transform: translateY(-50%);
        z-index: 0;
        min-width: 600px;
        will-change: opacity;
    }

    &__social-media {
        position: absolute;
        //background-color: rgba(229, 229, 229, 0.35);
        border-radius: 17.5px;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
     

        a {
            display: flex;
            flex-direction: column;
            opacity: 1;
            padding: 10px;
            transition: all .25s ease;

            img {
                width: 45px;
                height: 45px;
                filter: brightness(0.9);
            }

            &:hover {
                transform: scale(1.1);
            }
        }

    }
}

@media screen and (max-width: 800px) {
    #root {
        .App {
            .home-page {
                position: relative;
                overflow: hidden;

                &__text {
                    width: 90%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;

                    h1 {
                        font-size: 4rem;
                        line-height: 65px;
                    }

                    h3 {
                        font-size: 2rem;
                        //line-height:45px;
                        margin-top: 10px;
                    }

                    button {
                        margin-top: 40px;
                    }
                }

                &__image {
                    filter: brightness(0.4) !important;
                    position: relative;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }

                &__social-media {
                    top: 225px;
                    right: 5%;
                }
            }
        }
    }
}

@media screen and (max-width: 450px) {

    #root {
        .App {

            .ham-menu-wrapper {
                width: 325px;
            }

            .home-page {
                &__text {
                    top: 55%;

                    h1 {
                        font-size: 3rem;
                        line-height: 55px;
                    }

                    h3 {
                        font-size: 1.5rem;
                        margin-top: 7.5px;
                    }

                    button {
                        padding: 25px 65px;
                        font-size: 15px;
                        margin-top: 30px;
                    }
                }
            }
        }
    }
}



@media screen and (max-width: 1200px) {

    #root {
        .App {
            .home-page {
                &__image {
                    filter: brightness(0.65);
                }

                &__text {
                    left: 5%;
                }

                &__image {
                    right: 5%;
                }
            }
        }
    }
}