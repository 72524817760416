@import "../styles/variables.scss";



.ham-menu-wrapper {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 99;
    height: 100vh;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    z-index: 2000;
    transition: all .25s ease;
    opacity: 1;
    visibility: visible;

    .menu-border {
        position: relative;
        z-index: 9999;
        height: 2000px;
        margin: 0;
        padding: 0;
    }

    &__content {
        height: 100vh;
        padding: 30px;
        width: 300px;
        color: black;
        background-color: rgb(255, 255, 255);
        display: flex;
        align-items: center;
        justify-content: center;



        a {
            z-index: 9999;
        }

        nav {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            gap: 25px;

            a {
                font-size: 1.5rem;
                color: rgba($mid-color, 0.75);
                text-decoration: none;
                font-weight: 500;
                transition: all .25s ease;
            }

            a.active {
                color: $mid-color;
                font-size: 2rem;
                margin-top: 10px;
                margin-bottom: 10px;
                font-weight: 700;
            }
        }
    }
}

.ham-menu-wrapper.hidden {
    visibility: hidden;
    opacity: 0;
    right: -200px;
    position: fixed;
}