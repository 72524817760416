@import "../styles/variables.scss";

.App {
    overflow: hidden !important;
}

.reviews-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: black;
    overflow: hidden;
    height: auto;
   
    gap: 100px;
   
    .mobile-cloud {
        display: none;
    }

  
    &__content { 
        &__left {
            background-color: black;
            display: flex;
            flex-direction: column;
            color: white;
            align-items: center;
            justify-content:center;
            z-index: 100;
            //gap: 40px;
            .main-titles {
                opacity:0;
                will-change: opacity;
                text-align: center !important;
                position:relative;
             

                & > * {
                    text-align: center !important;
                }
            }
            .dottedArrow {
                opacity:0;
                will-change: opacity;
            }

            .phone {
                max-width: 450px;
            }
        }
        &__right {
            background-color: black;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            position:relative;
            z-index: 10;

            .alanImg {
                position:relative;
                z-index: 0;
                opacity:0;
                will-change: opacity;
            }
        }
    }
    &__form {
        width: 100%;
        height: 100vh;
        background-repeat: no-repeat;
        background-size: 1500px;
        background-image: "../img/sendReview.png";
        background-position: -400px;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: flex-end; 
        align-items: center;
        position:relative;
        z-index: 200;
        opacity:0;
        will-change: opacity;
        &__cloud {
            width: 80%;
            height: 100%;
            background-color: none;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            color: white;
            overflow: hidden;
            background-size: cover;
            background-position: 200px 0px;
            background-repeat: no-repeat;
            align-items: center;
            position:relative;
            z-index: 200;
            opacity:0;
            will-change: opacity;
            overflow: hidden;
            form {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 20px;
                .input-wrapper {
                    position: relative;

                    //background-color:red;
                    //width:100%;

                    &__border,
                    &__border--bottom {
                        width: 40px;
                        height: 40px;
                        position: absolute;

                    }

                    &__border {
                        left: -10px;
                        top: 3px;
                    }

                    &__border--bottom {
                        right: -10px;
                        bottom: -15px;
                        rotate: 180deg;
                    }

                    .input {
                        width: 400px;
                        height: 60px;
                        border-radius: 7.5px;
                        background-color: white;
                        margin-top: 20px;
                        color: $reviews-form-color;
                        padding-left: 10px;
                        border: 2.5px solid rgba($reviews-form-color, .5);
                        position: relative;
                        z-index: 1000;
                        outline: none;
                        transition: .25s ease all;
                        z-index: 9;
                        flex-shrink: 0;
                        &::placeholder,
                        &::placeholder {
                            color: $reviews-form-color;
                            font-size: 15px;
                            transition: all .25s ease;
                        }
                        &:focus {
                            border: 2.5px solid $reviews-form-color;
                            &::placeholder,
                            &::placeholder {
                                color: rgba($reviews-form-color, 0.5);
                                font-size: 12.5px;
                                position: relative;
                                top: -17px;
                                left: -10px;
                                padding: 5px;
                                z-index: 10;
                            }
                        }
                        &::before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border-radius: 7.5px;
                            border: 0.5px solid $reviews-form-color;
                            z-index: 10000;
                            transition: 0.3s;
                        }
                    }
                }
                .review-btn {
                    width: 200px;
                    height: 50px;
                    border-radius: 7.5px;
                    background-color: $reviews-form-color;
                    color: white;
                    font-size: 15px;
                    border: none;
                    align-self: flex-start;
                    margin-top: 20px;
                    transition: all .25s ease;
                    z-index: 100;
                    &:hover {
                        cursor: pointer;
                        //transform: scale(1.05); //scale skaluje tez fonta i wyglada niewyraznie
                        width: 220px;
                        background-color: rgba($reviews-form-color, .75);
                    }
                }
                .msg {
                    height: 200px !important;
                    padding-top: 10px;
                    outline: none;
                }
            }
        }
    }
}



@media screen and (max-width: 1000px) {
    .reviews-page__content__right {
        display:none;
    }

    .reviews-page {
        margin-top:0;
    }

   
}


@media screen and (max-width: 1100px) {
    
    .reviews-page__content {
        gap: 50px !important;	
    }
}