@import "../styles/variables.scss";

.newsletter-page{
    width: 100%;
    height: 60vh;
    // background-image: url(../../img/newsletter.jpg); to w reactcie
    background-size: 100%;
    background-position: 0 -80px;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    &__content{
        width: 70%;
        height: 70%;
        background: rgba(211, 204, 204, 0.5);
        mix-blend-mode: normal;
        backdrop-filter: blur(10px);
        border-radius: 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        &--alert{
            position: fixed;
            top: 0;
            right: 0;
        }
        &__header{
            width: 100%;
            height: 60%; 
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 50px;
            &__title{
                font-size: 70px;
                font-weight: 700;
                font-family: 'Inter';
                font-style: normal;
                font-weight: 900;
                font-size: 60px;
            }
            &__subtitle{
                font-size: 30px;
                font-weight: 350;
                width: 40%;
                position: relative;
                left: 10px;
            }
        }
        &__form{
            width: 100%;
            height: 40%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            align-items: center;
            &__el{
                display: flex;
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 20px;
                padding-top: 20px;
                position: relative;
                width: 30%;
                &__input{
                    width: 100%;
                    height: 60px;
                    border: 1.5px solid black;
                    border-radius: 7.5px;
                    background-color: #e4dfdf;
                    padding: 12px;
                    position: relative;
                    transition: .25s ease all;
                    font-size: 20px;
                    color: black;
                    outline: none;
                    &::placeholder {
                        color: black;
                        font-size: 20px;
                        transition: all .25s ease;
                        outline: none;
                    }
                    &:focus{
                        outline: none;
                        &::placeholder{
                            color: black;
                            font-size: 12.5px;
                            position: relative;
                            top: -17px;
                            left: -10px;
                            padding: 5px;
                        }
                    }
                    &__border{
                        &--upper{
                            top: 0;
                            left: 0;
                        }
                        &--lower{
                            bottom: 0;
                            right: 0;
                        }
                    }
                }
                &__button{
                    width: 100%;
                    height: 60px;
                    background-color: #000000;
                    color: white;
                    padding: 12px;
                    font-size: 20px;
                    border-radius: 7.5px;
                    font-weight: 300;
                    outline: none;
                    &:hover{
                        cursor: pointer;
                        transition: 0.3s;
                        background-color: rgb(228, 223, 223);
                        color: black;
                        border: none;
                        
                    }
                }
                &__border--upper, &__border--lower{
                    position: absolute;
                }
                &__border--upper{
                    top: 0;
                    left: 0;
                }
                &__border--lower{
                    bottom: 0;
                    right: 0;
                }
            }
            
        }
    }
}

@media(max-width: 1500px){
    .newsletter-page{
        &__content{
            width: 90%;
        }
    }
}

@media(max-width: 1400px){
    .newsletter-page{
        &__content{
            &__header{
                &__subtitle{
                    width: 60%;
                }
            }
        }
    }
}

@media(max-width: 1300px){
    .newsletter-page{
        height: 70vh;
        width: 100%;
        background-size: cover;
        background-position: center;
        &__content{
            height: 90%;
            width: 60%;
            align-items: center;
            &__header{
                width: 100%;
                display: flex;
                align-items: center;
                padding: 0;
                height: 35%;
                justify-content: center;
                &__title{
                    font-size: 65px;
                }
                &__subtitle{
                    font-size: 30px;
                    left: 0;
                    text-align: center;
                    width: 60%;
                }
            }
            &__form{
                height: 65%;
                flex-direction: column;
                align-items: center;
                &__el{
                    width: 70%;
                    
                }
            }
        }
    }
}

@media(max-width: 1200px){
    .newsletter-page{
        height: 80vh;
        &__content{
            width: 50%;
            &__header{
                &__subtitle{
                    width: 80%;
                }
            }
        }
    }
}

@media(max-width: 1100px){
    .newsletter-page{
        &__content{
            &__header{
                &__title{
                    font-size: 55px
                }
                &__subtitle{
                    width: 90%;
                    font-size: 28px;
                }
            }
            &__form{
                &__el{
                    width: 90%;
                }
            }
        }  
    }
}

@media(max-width: 1000px){
    .newsletter-page{
        &__content{
            width: 70%;
            &__header{
                &__title{
                    font-size: 50px;
                }
                &__subtitle{
                    width: 90%;
                    font-size: 25px;
                }
            }
        }
    }
}

@media(max-width: 700px){
    .newsletter-page{
        &__content{
            width: 80%;
        }
    }
}

@media(max-width: 600px){
    .newsletter-page{
        &__content{
            width: 90%;
        }
    }
}

