@import "../styles/variables.scss";

.buy-block {
    flex-shrink: 0;
    margin: 0;
    padding: 0;

    .pricing {
        &__normal-price {
            font-size: 30.2px;
            position: relative;
            font-weight: 600;
            position: relative;
            top: 5px;
            overflow: visible;
          
   

            span {
                font-size: 22.5px;
            }

            .duration {
                font-size: 15px;
                position: relative;
                bottom: 10px;
            }

            &__line {
                width: 67.5%;
                height: 1.75px;
                position: absolute !important;
                top: 55%;
                //left: -10%;
                margin-left: -7.5%;
                //transform: translateX(50%);
            }
        }

        &__discount-price {
            font-size: 55px;
            position: relative;
            font-weight: 700;
            height: 70px;

            span {
                font-size: 27.5px;
                left: 1.5px;
                position: relative;
            }
        }

        &__recurring {
            position: relative;
            bottom: 20px;
            left: 2.5px;
        }





    }

    .buy-btn {
        outline: none;
        border: none;
        //width: 225px;
        width: 100%;
        min-width: 200px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 25px;
        font-weight: bold;
        border-radius: 7.5px;
        transition: all .25s ease;
        margin-top: 15px;
        will-change: scale, cursor;

        &:hover {
            //width: 320px;
            scale: 1.05;
            cursor: pointer;
        }
    }
}



.line {
    width: 115%;
    position: relative;
    left: -7.5%;
    height: 1.25px;
    margin-top: 7.5px;
    margin-bottom: 7.5px;


}

@media screen and (max-width: 400px) {
    .buy-btn {
        width: 100% !important;
    }
}