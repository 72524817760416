@import "../styles/variables.scss";


@keyframes appear {
    from {
        opacity: 0;

    }
    to {
        opacity:1;
    }
}

.offer-page__content__training,
.offer-page__content__diet {

    width: 500px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding: 50px;
    transition: 0.25s ease all;
    flex-shrink: 0;
    

    &__title {
        text-align: left;
        font-size: 50px;

        &>* {
            margin: 20px;
        }

        h1 {
            font-size: 50px;
            line-height: 60px;
        }


        h2 {
            text-align: left;
            font-size: 20px;
            line-height: 22.5px;
        }

        ul {
            li {
                font-size: 20px !important;
                line-height: 22.5px;
                margin: 5px;
            }
        }
    }

    &__title {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: bold;
        font-size: 40px;
        line-height: 48px;
        text-align: right;
        color: $mid-color;
        margin-bottom: 20px;
    }

    &__text {
        font-family: 'Montserrat';
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        text-align: right;
        color: rgba($lightest-color, .6);
        margin-bottom: 20px;
    }

    &__btn {
        padding: 12.5px;
        background-color: $lightest-color;
        color: white;
        border: none;
        outline: none;
        border-radius: 10px;
        font-size: 15px;
        transition: all .15s ease;

        &:hover {
            cursor: pointer;
            background-color: $mid-color;
            padding: 12.5px 15px;
        }
    }
}

.offer-page__content__diet {
    align-items: flex-start;

    &__title {
        text-align: left;
    }

    &__text {
        text-align: left;
    }
}

.offer-page__content__training.flipped,
.offer-page__content__diet.flipped {
    background-image: none !important;
    border: 2.75px solid rgba($lightest-color, .6);
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    width: fit-content;
    flex-direction: row;
    margin: 40px;
    position: relative;
    transition: all .25s ease;
    

    &>*:not(.overlay) {
        margin: 15px;
    }

    .plan-photo {
        width: 50%;
        max-width: 400px;
        border-radius: 20px;
        min-width: 300px;
        border: 1.75px solid rgba($lightest-color, .6);
        cursor: pointer;
    }

    .offer-page__content__training__title,
    .offer-page__content__diet__title {
        text-align: left;
        font-size: 50px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        h1 {
            font-size: 60px;
            line-height: 60px;
            text-align: left;
        }


        h2 {
            text-align: left;
            font-size: 27.5px;
            line-height: 22.5px;
        }

        ul {
            max-width: 600px;
            li {
                font-size: 20px !important;
                line-height: 22.5px;
                margin: 6.5px;
            }
        }

    }
}

.plan-photo-zoomed {
    position:fixed;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    width: 90vw;
    z-index: 1000;
    opacity:0;
    display:none;
    z-index: 10000;
    margin:0 !important;
    cursor:pointer;
}


@media screen and (max-width: 900px) {

    .offer-page__content__training.flipped,
    .offer-page__content__diet.flipped {

        display: flex;
        flex-direction: column;
        height: auto;
        width: 90%;
        margin: 20px;

        &>*:not(.overlay):not(.plan-photo-zoomed) {
            margin: 12.5px;
        }

        .offer-page__content__training__title.flipped,
        .offer-page__content__diet__title.flipped {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            margin: 0;
            ul,
            li,
            h2 {
                text-align: center;
            }
        }
    }
}
@media screen and (max-width: 600px) {


.offer-page__content__diet,.offer-page__content__training {
    width: 90%;
    background: none !important;
    height: auto;
    padding: 20px;
    img:not(.contact-img):not(.logo) {
        min-width: 250px;
    }
}
.offer-page__content__diet {
    border-bottom: 1.5px;
}

}

.overlay {
    width:100%;
    height:100vh;
    background:rgba(0,0,0,0.5);
    position: fixed;
    top:0;
    left:0;
    z-index: 1000;
    display:none;
}

@media screen and (min-width: 600px) {
    .plan-photo-zoomed {
        width: 600px;
    }
}