@import "../styles/variables.scss";

.glowup {
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    flex-wrap:nowrap;
    flex-shrink: 0;
    gap: 10px;
    max-width: 540px;
   
    width: 70%;
    max-height: 375px;
    margin-right: 1.5%;
    
    & > * {
        flex-wrap: nowrap;
        width:70%;
       
    }

    &__header {  
        display:flex;
        gap: 10px;
        flex-shrink:0;
        justify-content:center;
        flex-wrap: nowrap;

        img {
            width: 40%;
            border: 2px solid $reviews-form-color;
            border-radius: 20px;
            position:relative;
            transition: all .2s ease;
            will-change: scale;
            z-index: 100;
            min-width: 100px;
            min-height: 100px;
            //aspect-ratio: 1/1;


           &:hover {
                cursor:pointer;
                z-index: 1000;
                scale: 2.75;
           }
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        gap: 17.5px;

        &__header {
            color: $glowup-main-color;
            font-weight: 800;
            letter-spacing: .5px;
            position: relative;
            left: -5px;
            font-size: 1.75rem;
            top: 2px;

        }

        &__body {
            font-weight: 400;
            color: rgb(140, 140, 140);
            font-size: 1.1rem;
            position:relative;
            min-width: 175px;
            max-width: 250px;
        }

        &__footer {
            position: relative;
            left: -5px;
            top: 5px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: $glowup-main-color;
            gap: 10px;

            img {
                width: 24px;
                height: 24px;
            }

            p {
                font-weight: bold !important;
                position: relative;
                top: 1px;
                font-size: 1.25rem;
            }
        }
    }
}
