


//import colors

@import "../styles/variables.scss";

@keyframes wave {
    0% {
        transform: scaleX(1.1);
    }
    50% {
        transform: scaleX(1.5);
    }
    100% {
        transform: scaleX(1.1);
    }
}


@function randomNum($min, $max) {
    $rand: random();
    $randomNum: $min + floor($rand * (($max - $min) + 1));
  
    @return $randomNum;
  }




.wave-border {
    height:100px;
    width:100%; 
    overflow:hidden !important;
    margin:0;

        .shape-fill {
            animation-name: wave;
            animation-timing-function: ease-in-out;
            animation-iteration-count: infinite;
            box-shadow: $box-shadow-light !important;
        }

        .shape-fill:nth-child(1) {
            animation-name: wave;
            fill: $wave-vector-color-2;     
        }

        .shape-fill:nth-child(2) {
            animation-name: wave;
            animation-timing-function: ease-in-out !important;
            fill: $wave-vector-color-1;
        }

        .shape-fill:nth-child(3) {
            animation-name: wave;
            animation-timing-function: ease-in !important;
            fill: white;
        }  

    }

    .wave-border--rotated {
        margin:0;
        height:100px;
        width:100%;

      
        overflow:hidden !important;
        rotate:180deg;
    
    
      
    
    
    
            .shape-fill {
                animation-name: wave;
                animation-duration: 25s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 0.1);
                -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 0.1);
                box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 0.1);
    
            }
    
            .shape-fill:nth-child(1) {
                animation-name: wave;
                fill: $wave-vector-color-2;
               
            }
    
            .shape-fill:nth-child(2) {
                animation-name: wave;
                animation-duration: 20s !important;
                animation-delay: 3s;
                animation-timing-function: ease-in-out !important;
                fill: $wave-vector-color-1;
            }
    
            .shape-fill:nth-child(3) {
                animation-name: wave;
                animation-delay: 6s;
                animation-timing-function: ease-in !important;
                fill: white;
            }  
    
            &--rotated {
            transform: rotate(180deg);
            overflow:hidden;
    
            .shape-fill {
                animation-name: wave;
                animation-duration: 25s;
                animation-timing-function: ease-in-out;
                animation-iteration-count: infinite;
                -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 0.1);
                -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 0.1);
                box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 0.1);
    
            }
    
            .shape-fill:nth-child(1) {
                animation-name: wave;
                fill: $wave-vector-color-2;
               
            }
    
            .shape-fill:nth-child(2) {
                animation-name: wave;
                animation-duration: 20s !important;
                animation-delay: 3s;
                animation-timing-function: ease-in-out !important;
                fill: $wave-vector-color-1;
            }
    
            .shape-fill:nth-child(3) {
                animation-name: wave;
                animation-delay: 6s;
                animation-timing-function: ease-in !important;
                fill: white;
            }  
            
        }
        }