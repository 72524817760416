@import "../styles/variables.scss";

.about-me {
    color: white;
    display: flex;
    overflow: visible;
    align-items: center;
    gap: 20px;


    &__images {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0 !important;
        margin: 10px;
        height: fit-content;

        .alanImg {
            will-change: transform;
            position: relative;
            top: 20%;
            opacity: 1;
        }

        .line {
            will-change: transform;
            position: relative;
            transform: translateY(-50%);
            z-index: 100;
            opacity: 1;
            margin-top: -100px;
        }
    }

    &__text {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        //margin-top: 125px;
        position: relative;
        overflow: visible;
        height: fit-content;

        &>* {
            width: 90%;
        }

        .main-titles {
            &__title {
                text-align: center;
            }
            &__subtitle {
                text-align: center;
            }
        }

        

        img {
            max-width: 750px;
            min-width: 550px;
            @media screen and (max-width: 450px) {
               
                    max-width: 95vw !important;
            }
               
        }

        &__content {

                @media screen and (max-width: 450px) {
                    img {
                        max-width: 95vw !important;
                    }
            }
        }
    }
}


@media screen and (max-width: 1000px) {

    .alanImg {
        position: absolute;
        width: 100vw;
        margin-top: 50%;
        z-index: 1;
    }

    .about-me__text {
        width: 100%;
        margin-top: 0;

        img {
            width: 60%;
        }

       


        &__content {
            position: relative;
            z-index: 10;
        }
    }
}

@media screen and (max-width: 450px) {

    img.about-me__text__content {
        min-width: 95vw;
    }
   
}



         
    

