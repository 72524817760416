@import "../styles/variables.scss";

.glowups-page {
    width: 100%;
    background-color: black;
    font-family: 'Inter';
    z-index: 100;
    position: relative;


    .glowups-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow-x: scroll;
        scrollbar-width: thin;
        scrollbar-color: #ccc transparent;
        height: auto;
        width: 100vw;
        flex-shrink: 0;
        margin-bottom: 50px;
        


        &::-webkit-scrollbar {
            height: 8px;

            &:hover {
                scale: 1.1;

            }
        }

        &::-webkit-scrollbar-track {
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #ccc;
            border-radius: 4px;

            &:hover {
                background-color: #999;
            }
        }


    }

    &__header {
        align-items:center;
        padding-bottom: 0 !important;
        
        .left-scroll, .right-scroll {
        
            position: relative;
            left: 30%;
            width: 450px;
            opacity: 0;
            will-change: opacity;
        }

        &>* {
            width: 50%;
        }

        &__content {

            .main-titles {
                opacity: 1;
                will-change: opacity;

               
            }
        }
    }

    &__content {
        width: 100vw;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        flex-wrap: nowrap;
        margin-left: auto;
        margin-right: auto;
        height: fit-content;
        overflow: scroll;
        overflow-y: hidden;
        max-width: none !important;
    }
}


@media(max-width: 1000px) {
    .glowups-page__header {
        margin: 0 !important;
        padding: 0 !important;

        &__content {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0;
            padding: 0;
        }

        img {
            display: none;
        }
    }
}


.left-scroll,
.right-scroll {
    margin: 5px;
    padding: 25px;
    color: white;
    background-color: transparent;
    outline: none;
    border: none;
    position: absolute;
    z-index: 1000;
    -webkit-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 0.25);
    -moz-box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 0.25);
    box-shadow: 8px 8px 24px 0px rgba(66, 68, 90, 0.25);
    cursor: pointer;
    border-radius: 50%;
    background-color: $glowup-main-color;
    will-change: scale;
    transition: all .25s ease;


    &:hover {
        svg {
            fill: white;

        }

        scale: 1.1;
    }

    svg {
        width: 30px;
        height: 30px;
        fill: white;
        background-color: transparent;
        transition: all .25s ease;
        will-change: fill;
    }
}

.right-scroll {
    transform: rotate(180deg);
    right: 0;
}

.left-scroll {
    left: 0;
}


.glowup-wrapper {
    display: flex;
    justify-content: center;
    align-items:center;
    flex-shrink: 0;
    overflow: visible;
    background-size: 95%;
    background-repeat: no-repeat;
    background-position: center;
    height: 660px;
}


@media screen and (max-width: 350px) {

    .glowup-wrapper {
        width: 100vw;
        will-change: scale;
       
        background-size: 115%;
       

        
    }

    .left-scroll, .right-scroll {

        scale: 0.7;
    }
}

@media screen and (min-width: 351px) and (max-width: 400px) {

    .glowup-wrapper {
        width: 100vw;
        will-change: scale;
        //scale: 0.9;
        background-size: 110%;
    }

    .left-scroll, .right-scroll {

        scale: 0.9;
    }

    .main-titles__subtitle {
        width: 100%;
    }





}


@media screen and (min-width: 400px) and (max-width: 767px) {
    .glowup-wrapper {
        width: 100vw;
    }
}

@media screen and (min-width: 768px) and (max-width: 1150px) {
    .glowup-wrapper {
        width: 50vw;
    }
}

@media screen and (min-width: 1150px) and (max-width:1500px) {
    .glowup-wrapper {
        width: 33vw;
        gap: 12.5px;
    }
}

@media screen and (min-width: 1501px) and (max-width:1800px) {
    .glowup-wrapper {
        width: 25vw;
    }
}

@media screen and (min-width: 1801px) {
    .glowup-wrapper {
        width: 2220vw;
        max-width: 550px;
    }
}


@media screen and (min-width: 500px) and (max-width: 768px) {
    .glowup-wrapper {
        width: 100vw;
        max-width: 1500px;
        background-size: 72.5%;
        background-position: 50% 50%;
        
    }


}

@media screen and (max-width: 1100px) {
    .glowups-page__header {
        gap: 25px !important;
    }
}