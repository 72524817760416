.sign-in {
    display:flex;
    max-width: 500px;
    flex-direction: column;
    text-align: center;
    padding: 30px;
    gap: 10px;
    background-color: rgb(220,220,220);
    margin-left:auto;
    margin-right: auto;
    margin-top: 100px;
    input {
        padding: 10px;
    }
}