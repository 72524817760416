.promo-block {
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: space-between;
    width:100%;
    gap:20px;


    .info-block {
        color:white;
        padding: 25px 20px 20px 20px;
      
        position:relative;
        border-radius: 15px;

        p {
            font-size: 20px;
            font-weight: 500;
            margin: 0;
            padding: 0;
            white-space: nowrap;


            .duration, .currency {
                font-size: 15px;
                position: relative;
                margin: 1.5px;
            }

            .duration {
                top: -5px;
                font-size: 15px;
                left: 1.5px;
            }
            .currency {
                font-size: 15px;
                //right: 1.5px;
            }
        }


        .percentage {
            position: absolute;
            right: 20px;
            top:-5px;
            background-color: #FF5B5B;
            padding: 3.75px 5px;
            font-weight: 500;
            border-radius: 5px;


        }

    }
}

.row {
    display:flex;
    flex-direction: row;
    gap: 20px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}


@media screen and (max-width: 550px ) {

    .row {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
    }


    
}