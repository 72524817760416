

@keyframes top-down {
    0% {
        transform: translateX(0);
    }

    50% {
        transform: translateX(25%);
    }

    100% {
        transform: translateX(0);
    }

}

.scroll-down {
    position: absolute;
    bottom: 50px;
    left: calc(50% - 34px); ;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: white;
    padding: 25px;
    rotate: 90deg;
    transform-origin: 50% 50%;
    animation: top-down 3s ease infinite forwards;


    img {
        width: 35px;
        height: 35px;
        position: relative;
        left: 4px;
    }
}


@media screen and (max-width: 768px) {
    .scroll-down {
        padding: 20px;
      

        img {
            width: 30px;
            height: 30px;
        }
        
    }
}
@media screen and (max-width: 450px) {
    .scroll-down {
        padding: 20px;
        bottom: 30px;

        img {
            width: 30px;
            height: 30px;
        }
        
    }
}