@import "../styles/variables.scss";


.phone {
    position:relative;
    margin: 5px;
    width: 435px;
    img {
        width: 100%;
    }

    .link {
        font-family: system-ui,-apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,Oxygen,Ubuntu,Cantarell,'Fira Sans','Droid Sans','Helvetica Neue',sans-serif;
        position:absolute;
        width:80%;
        text-align: center;
        left: 50%;
        top: 9.5%;
        transform: translate(-50%,0);
        color: #333;
    }

    iframe {
        position:absolute;
        left:6.5%;
        top: 13.75%;
        border:0;
        height: 73.25%;
        width: 86.75%;
    }
}




@media screen and (max-width: 500px) {
    .phone {
        scale: 0.925;
       
    }
}
@media screen and (max-width: 450px) {
    .phone {
        scale: 0.85;
    }
}
@media screen and (max-width: 400px) {
    .phone {
        scale: 0.775;
    }
}


